<template>
  <section>
    <TopBanner
      title="Contact"
      :src="require('@/assets/images/banners/banner_contact.jpg')"
    />
    <v-container class="content-container">
      <section id="contact-us">
        <div class="text-center">
          <p
            class="primary--text text-h5 text-sm-h3 font-weight-bold font-secondary"
          >
            Welcome to Contact Us
          </p>
          <p
            class="primary--text text-body-1 text-sm-h5 font-weight-light font-secondary"
          >
            We'll help you find a solution.
          </p>
          <p class="error--text font-weight-light font-secondary">
            * For Mortgage Payment Inquiries: Please email
            <a
              :href="`mailto:accounting@newwavelending.com`"
              class="error--text"
            >
              accounting@newwavelending.com
            </a>
          </p>
        </div>
        <v-card class="my-10 py-8" color="beige" flat tile>
          <v-row>
            <v-col cols="12" md="5">
              <br /><br />
              <div class="mx-auto" style="width: 70%">
                <p class="text-h2 font-weight-bold">Contact</p>
                <div class="text-caption">
                  Ready to start something new and amazing with us?
                </div>
                <div class="text-caption">Just say hello!</div>
                <v-btn
                  color="primary"
                  rounded
                  elevation="0"
                  class="font-weight-light my-5 px-7"
                  @click="downloadBecomePartnerForm"
                >
                  Become a Partner
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="7">
              <div class="mx-auto" style="width: 70%">
                <div class="text-uppercase grey--text text-caption">
                  Email Address
                </div>
                <div class="text-h6 font-weight-bold text-subtitle-1">
                  <v-icon> mdi-email-outline </v-icon>
                  <a
                    :href="`mailto:info@newwavelending.com`"
                    class="black--text text-decoration-none"
                  >
                    info@newwavelending.com
                  </a>
                </div>
                <br />
                <div class="text-uppercase grey--text text-caption">Phone</div>
                <div class="text-h6 font-weight-bold text-subtitle-1">
                  <v-icon> mdi-phone </v-icon>
                  <a
                    :href="`tel:+${$store.state.company.tel}`"
                    class="black--text text-decoration-none"
                  >
                    {{
                      $store.state.company.tel | VMask($store.state.mask.phone)
                    }}
                  </a>
                </div>
                <br />
                <div class="text-uppercase grey--text text-caption">
                  Address
                </div>
                <div class="text-h6 font-weight-bold text-subtitle-1">
                  <v-icon> mdi-google-maps </v-icon>
                  <a
                    :href="$store.getters.googleMapLink"
                    class="black--text text-decoration-none"
                    target="_blank"
                  >
                    {{ $store.getters.companyFullAddress }}
                  </a>
                </div>
                <br />
                <div class="text-uppercase grey--text text-caption">
                  Office hour
                </div>
                <div class="text-h6 font-weight-bold text-subtitle-1">
                  <v-icon> mdi-clock-time-two-outline </v-icon>
                  <a
                    :href="$store.getters.googleMapLink"
                    class="black--text text-decoration-none"
                    target="_blank"
                  >
                    Mon-Fri 8:30AM - 5:30PM (PST)
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </section>

      <section>
        <v-row>
          <v-col cols="12">
            <v-form
              ref="contactform"
              lazy-validation
              @submit.prevent="submitContact"
            >
              <v-card :disabled="contactform.loading" tile flat>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="contactform.name"
                      :label="
                        'Full Name ' +
                        (contactform.isAnonymous ? '(Optional)' : '')
                      "
                      :rules="contactform.isAnonymous ? [] : required"
                      dense
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="contactform.email"
                      :label="
                        'Email ' + (contactform.isAnonymous ? '(Optional)' : '')
                      "
                      :rules="contactform.isAnonymous ? [] : [required, email]"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="contactform.phone"
                      :label="
                        'Phone ' + (contactform.isAnonymous ? '(Optional)' : '')
                      "
                      v-mask="'(###) ###-####'"
                      placeholder="(000) 000-0000"
                      :rules="contactform.isAnonymous ? [] : required"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-text-field
                      v-model="contactform.accountExecutive"
                      :label="'Account Executive Name (Optional)' "
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12">
                    <v-textarea
                      v-model="contactform.detailMessage"
                      :label="'Type your message here...'"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      :loading="contactform.loading"
                      color="primary"
                      type="submit"
                      elevation="0"
                      block
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </section>
    </v-container>
    <Footer />
  </section>
</template>

<script type="text/javascript">
import TopBanner from "@/components/TopBanner";
import Footer from "@/views/layouts/Footer";
import axios from "axios";
import { rules } from "@/mixins";
import { gmapApi } from "vue2-google-maps";

export default {
  components: {
    TopBanner,
    Footer,
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    // this.$refs.mapRef.$mapPromise.then((map) => {
    //   console.log(map);
    // });
  },
  data() {
    return {
      contactform: {
        isAnonymous: false,
        name: null,
        email: null,
        phone: null,
        accountExecutive: null,
        detailMessage: null,
        loading: false,
      },
    };
  },
  methods: {
    initContactform() {
      return {
        isAnonymous: false,
        name: null,
        email: null,
        phone: null,
        accountExecutive: null,
        detailMessage: null,
        loading: false,
      };
    },
    downloadBecomePartnerForm() {
      var link = document.createElement("a");
      link.href =
        `https://newwavelending.com/web_resources/FORMS/Broker%20Application%20Package%20NWL.pdf?var=` +
        Math.random();
      link.download = "NewWaveLending_Broker_Application_Form.pdf";
      link.dispatchEvent(new MouseEvent("click"));
    },
    submitContact() {
      if (this.$refs.contactform.validate()) {
        this.contactform.loading = true;

        axios
          .post(
            `${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/ContactUs`,
            this.contactform,
            {
              headers: {
                Authorization: `Basic ${btoa(
                  this.$store.state.basicAuth.userName +
                    ":" +
                    this.$store.state.basicAuth.password
                )}`,
              },
            }
          )
          .then(() => {
            this.contactform = this.initContactform();
            this.$refs.contactform.resetValidation();

            this.$store.commit("SHOW_STATUS_DIALOG", {
              status: "success",
              message: "Your request has been carried out successfully.",
            });
          })
          .catch(() => {
            this.$store.commit("SHOW_STATUS_DIALOG", {
              status: "error",
              message: "Sorry, your request failed.",
            });
          })
          .finally(() => {
            this.contactform.loading = false;
          });
      }
    },
  },
  mixins: [rules],
};
</script>
